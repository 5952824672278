import React, { useRef, useEffect, useState } from 'react';
import './App.css';
import background from './images/backgroundfb.png';

function App() {
  const textareaRef = useRef();
  const buttonRef = useRef();
  const [generatedNames, setGeneratedNames] = useState([]);

  useEffect(() => {
    const image = new Image();
    image.src = background;

    image.onload = () => {
      const width = image.naturalWidth * 0.8;
      const height = image.naturalHeight * 0.58;
      textareaRef.current.style.width = `${width}px`;
      textareaRef.current.style.height = `${height}px`;

      const width_button = image.naturalWidth * 0.8;
      const height_button = image.naturalHeight * 0.04;
      buttonRef.current.style.width = `${width_button}px`;
      buttonRef.current.style.height = `${height_button}px`;
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('https://apireel.mmoshop.me/generate_name');
      const data = await response.json();
      setGeneratedNames(data);
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  const handleGenerateClick = async () => {
    // Clear the textarea before updating it with the generated names
    textareaRef.current.value = '';

    // Fetch data when the button is clicked
    const data = await fetchData();

    // Update textarea with the fetched names
    textareaRef.current.value = data.join('\n');
  };

  return (
    <div className="App ">

      <header className="App-header flex">
      
      <div className='title image-container '>
        <h2 >
        Name Generator
        </h2>
      </div>

      <div className=''>
          

        <div className="container">
        <div className="image-container ">
            <img src={background} className="background-image" alt="logo" />
        </div>
        <textarea ref={textareaRef} className="text-overlay focus:outline-none " placeholder="Name"></textarea>
          <button ref={buttonRef} className="button-overlay" onClick={handleGenerateClick}>
            Generate
          </button>
        
</div>

        
      </div>
      
      </header>
      
    </div>
  );
}

export default App;
